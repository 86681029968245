import React from 'react';

interface CertificateAvatarProps {
  hex: string;
}

const CertificateAvatar: React.FC<CertificateAvatarProps> = ({ hex }) => {
  return (
    <svg
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.286 65.3945H70.5093V67.0616H71.608L71.286 65.3945Z"
        fill="#BAAB27"
      />
      <path
        d="M22.7136 65.3755L22.3726 67.0615H23.4713V65.3944L22.7136 65.3755Z"
        fill="#BAAB27"
      />
      <path
        d="M79.2804 72.5176C77.6701 67.3459 63.8979 63.1782 46.9241 63.1782C29.9503 63.1782 16.3296 67.3459 14.7193 72.5176C14.2268 74.1278 13.6774 76.7232 13.147 79.6027C21.6907 88.4874 33.7012 94.0001 46.9999 94.0001C60.2985 94.0001 72.309 88.4684 80.8527 79.6027C80.3412 76.7232 79.7919 74.1089 79.2804 72.5176Z"
        fill={`#${hex}`}
      />
      <path
        d="M54.3693 53.4409H39.6309C41.9231 60.3744 39.8203 63.5381 39.8203 63.5381L44.9162 64.6936H49.0839L54.1798 63.5381C54.1798 63.5381 52.077 60.3744 54.3693 53.4409Z"
        fill="#E2A379"
      />
      <path
        d="M46.9622 60.0522L39.8203 63.5379C39.8203 63.5379 43.0029 68.8612 46.9433 68.8612C50.8836 68.8612 54.1799 63.5379 54.1799 63.5379L46.9622 60.0522Z"
        fill="#E2A379"
      />
      <path
        d="M46.943 61.1704C41.6008 61.1704 39.8579 63.5195 39.8579 63.5195C39.8579 63.5195 41.6955 73.3324 46.9998 73.3324C52.3041 73.3324 54.1606 63.5195 54.1606 63.5195C54.1606 63.5195 52.2662 61.1704 46.943 61.1704Z"
        fill="#E2A379"
      />
      <path
        d="M57.0214 63.1024C57.0214 63.1024 49.4628 88.8662 47 88.8662C44.5373 88.8662 36.9787 63.0835 36.9787 63.0835C31.3902 63.6708 26.4837 63.9928 22.7139 65.3757L30.5188 91.0259C35.0464 92.7308 39.915 93.7348 44.973 93.9432C45.6361 93.9622 46.3181 93.9811 47 93.9811C47.682 93.9811 48.3451 93.9622 49.027 93.9432C54.104 93.7348 58.9537 92.7119 63.4813 91.0259L71.2862 65.3947C67.4974 64.0307 62.5909 63.6897 57.0214 63.1024Z"
        fill="#D3C624"
      />
      <path
        d="M35.4824 92.5601C36.297 92.7684 37.1116 92.9389 37.9262 93.1094C37.0927 92.9579 36.2781 92.7684 35.4824 92.5601Z"
        fill="#D3C624"
      />
      <path
        d="M56.0928 93.1094C56.9074 92.9579 57.722 92.7684 58.5365 92.5601C57.722 92.7684 56.9074 92.9579 56.0928 93.1094Z"
        fill="#D3C624"
      />
      <path
        d="M37.9826 93.1288C37.9637 93.1288 37.9447 93.1288 37.9258 93.1099C37.9447 93.1099 37.9637 93.1288 37.9826 93.1288Z"
        fill="#D6D6D6"
      />
      <path
        d="M31.4092 63.6519C29.8558 63.8224 28.3971 64.0118 27.0142 64.2581L33.0005 91.8594C33.815 92.1057 34.6296 92.333 35.4442 92.5414H35.4632C36.2778 92.7498 37.0924 92.9203 37.9069 93.0908C37.9259 93.0908 37.9448 93.0908 37.9638 93.1097L31.4092 63.6519Z"
        fill="#D6D6D6"
      />
      <path
        d="M56.0171 93.1288C56.036 93.1288 56.055 93.1288 56.0739 93.1099L56.0171 93.1288Z"
        fill="#D6D6D6"
      />
      <path
        d="M62.4391 63.6709C61.1509 69.5435 56.036 93.053 56.0171 93.1288C56.036 93.1288 56.055 93.1288 56.0739 93.1098C56.8885 92.9583 57.7031 92.7688 58.5177 92.5605C58.5366 92.5605 58.5366 92.5605 58.5556 92.5605C58.9534 92.4657 59.3702 92.3521 59.768 92.2384C60.1848 92.1247 60.5826 92.0111 60.9994 91.8785L66.8341 64.2771C65.4512 64.0308 63.9736 63.8414 62.4391 63.6709Z"
        fill="#D6D6D6"
      />
      <path
        d="M47.0005 11.6694C37.7748 11.6694 30.2729 15.6856 30.2729 33.1898C30.2729 39.1193 31.4475 43.7605 33.2093 47.3409C37.0738 55.2216 43.7611 57.9685 47.0005 57.9685C50.2399 57.9685 56.9271 55.2216 60.7917 47.3409C62.5535 43.7605 63.728 39.1193 63.728 33.1898C63.728 15.6856 56.2262 11.6694 47.0005 11.6694Z"
        fill="#F4B382"
      />
      <path
        d="M30.5759 33.7202C28.9846 33.9854 27.7532 34.6864 28.6625 39.9528C29.5719 45.2192 30.6896 45.4655 32.2808 45.2003L30.5759 33.7202Z"
        fill="#F4B382"
      />
      <path
        d="M63.4437 33.7202L61.7388 45.2003C63.3301 45.4655 64.4478 45.2192 65.3571 39.9528C66.2474 34.6864 65.035 34.0044 63.4437 33.7202Z"
        fill="#F4B382"
      />
      <path
        d="M47 11.1958C36.0125 11.1958 30.2725 15.6855 30.2725 33.1898L37.6227 20.2321C37.6227 20.2321 42.8323 21.0467 47 21.0467C51.1677 21.0467 55.7521 20.2321 55.7521 20.2321L63.7275 33.1898C63.7275 15.6855 57.9875 11.1958 47 11.1958Z"
        fill="#30302E"
      />
      <path
        d="M34.1182 26.2563L31.3524 26.6731C30.5567 28.738 30.2915 31.2954 30.2915 33.7771C30.2915 33.7771 31.9586 33.4172 31.9586 34.2696L32.489 42.4345L33.2278 42.0746C33.152 37.7175 34.1182 26.2563 34.1182 26.2563Z"
        fill="#30302E"
      />
      <path
        d="M62.6667 26.6729L59.9009 26.5972C59.9009 26.5972 60.867 37.7173 60.8291 42.0744L61.5679 42.4343L62.0984 34.2695C62.0984 33.417 63.7654 33.7769 63.7654 33.7769C63.7465 31.2763 63.4813 28.7378 62.6667 26.6729Z"
        fill="#30302E"
      />
      <path
        d="M61.3973 14.4919C58.8398 10.7031 51.8306 7.42578 47.0378 7.42578C42.2449 7.42578 35.1978 10.7031 32.6403 14.4919C30.2534 18.0155 29.6282 28.0179 30.5186 28.0179H63.519C64.1063 28.0179 63.7842 18.0155 61.3973 14.4919Z"
        fill="#E5AA17"
      />
      <path
        d="M30.1587 26.8057L30.3292 30.7839C30.3292 30.7839 37.509 31.5227 46.9999 31.5227C56.4908 31.5227 63.6517 30.7839 63.6517 30.7839L63.6895 26.8057H30.1587Z"
        fill="#EFC76C"
      />
      <path
        d="M46.9999 6.78223C43.7794 6.78223 41.1841 7.42632 41.1841 8.77134V13.0716C41.1841 14.4166 43.7794 29.5339 46.9999 29.5339C50.2014 29.5339 52.8157 14.4166 52.8157 13.0716V8.77134C52.8157 7.44527 50.2014 6.78223 46.9999 6.78223Z"
        fill="#E5AA17"
      />
      <path
        d="M41.2031 8.77104C41.1842 8.7521 41.1652 8.7521 41.1652 8.77104C41.0137 9.13098 40.9568 9.49092 40.9 9.85085C40.8621 10.0213 40.8621 10.2108 40.8432 10.3813C40.8242 10.5518 40.8242 10.7412 40.8242 10.9117C40.8242 11.0822 40.8242 11.2716 40.8432 11.4421C40.8621 11.6126 40.8621 11.8021 40.9 11.9726C40.9568 12.3325 41.0326 12.6924 41.1652 13.0524L41.1842 13.0713C41.2031 13.0713 41.2031 13.0713 41.2031 13.0524L41.222 10.9117L41.2031 8.77104Z"
        fill="#C18D17"
      />
      <path
        d="M53.1567 10.3811C53.1378 10.2106 53.1378 10.0212 53.0999 9.8507C53.043 9.49077 52.9673 9.13083 52.8347 8.7709L52.8157 8.75195C52.7968 8.75195 52.7968 8.75195 52.7968 8.7709L52.7778 10.9116L52.7968 13.0522C52.7968 13.0522 52.7968 13.0712 52.8157 13.0712C52.8347 13.0712 52.8347 13.0712 52.8347 13.0522C52.9862 12.6923 53.043 12.3324 53.0999 11.9724C53.1378 11.8019 53.1378 11.6125 53.1567 11.442C53.1757 11.2715 53.1757 11.0821 53.1757 10.9116C53.1757 10.7411 53.1757 10.5706 53.1567 10.3811Z"
        fill="#C18D17"
      />
      <path
        d="M50.4481 8.10793C50.4481 6.76291 48.9136 5.68311 47.0003 5.68311C45.0869 5.68311 43.5525 6.78186 43.5525 8.10793L41.2603 26.4268C41.2603 27.7718 45.1059 28.8516 47.0003 28.8516C48.8947 28.8516 52.7403 27.7528 52.7403 26.4268L50.4481 8.10793Z"
        fill="#F4B21E"
      />
      <path
        d="M62.9887 24.4755C60.6017 24.3807 53.7819 25.8963 46.9999 25.8963C40.3127 25.8963 33.6634 24.3807 31.3522 24.4755C29.06 24.4755 28.3022 28.9462 28.3022 28.9462C28.3022 28.9462 36.3534 29.7608 47.0189 29.7608C57.6843 29.7608 65.6976 28.9462 65.6976 28.9462C65.6976 28.9462 64.9778 24.4755 62.9887 24.4755Z"
        fill="#E5AA17"
      />
      <path
        d="M59.446 25.2715C58.0441 25.3662 56.6612 25.442 55.2594 25.4988C52.4746 25.6125 49.6709 25.7072 46.8861 25.6882C44.1014 25.7072 41.2976 25.6125 38.5129 25.4988C37.111 25.442 35.7281 25.3662 34.3263 25.2715C32.9244 25.1957 31.5415 25.082 30.1396 24.9873C31.5226 25.1767 32.9055 25.3283 34.3073 25.4798C35.6902 25.6125 37.0921 25.7261 38.4939 25.8208C41.2787 26.0103 44.0824 26.0861 46.8861 26.105C49.6898 26.0861 52.4746 26.0103 55.2783 25.8208C56.6802 25.7261 58.0631 25.6125 59.4649 25.4798C60.8478 25.3283 62.2497 25.1957 63.6326 24.9873C62.2307 25.082 60.8478 25.1957 59.446 25.2715Z"
        fill="#C18D17"
      />
    </svg>
  );
};

export default CertificateAvatar;
