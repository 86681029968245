import styled, { css } from 'styled-components';

interface MenuItemProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 50px;

  ul {
    list-style-type: none;
    display: flex;

    margin: 0;
    padding: 0;
    overflow: visible;
    background-color: #001e46;
  }

  li {
    text-decoration: none;
  }
`;

export const MenuItem = styled.button<MenuItemProps>`
  display: flex;
  width: 170px;
  max-width: 170px;
  height: 50px;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 0;

  position: relative;

  ${props =>
    props.isSelected
      ? css`
          background-color: #36a9e0;

          p {
            font-size: 18px;
            color: #001e46;
          }
        `
      : css`
          p {
            font-size: 18px;
            color: #fff;
            transition: color 0.2s;
          }

          .border {
            transition: background-color 0.2s;
          }

          &:hover {
            p {
              color: #36a9e0;
            }

            .border {
              content: '';
              width: 80%;
              height: 3px;
              background-color: #36a9e0;
              position: absolute;
              bottom: 0px;
            }
          }
        `}

  @media screen and (max-width: 850px) {
    width: 20vw;
  }
`;
