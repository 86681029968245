import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px;
  background: #001e46;
`;

export const HeaderContent = styled.div`
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  position: relative;

  color: #36a9e0;

  h1 {
    font-size: 28px;
    margin-bottom: 65px;
  }

  // '>' pq queremos estilizar apenas a primeira img (logo)
  // ou seja, elemento diretamente abaixo do HeaderContent
  > img {
    float: left;
    height: 119px;
  }

  > button {
    display: flex;
    background: transparent;
    border: 0;
    align-items: center;

    h2 {
      color: #fff;
      font-size: 16px;
      margin-left: 5px;
    }

    svg {
      color: #fff;
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .top-menu {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
