import { shade } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import hexRgb from 'hex-rgb';

import altrosSymbolNameImg from '../../assets/altros-symbol-name.png';

interface BackgroundFadeProps {
  hex: string;
}

interface CertificateCardHeaderProps {
  hex: string;
}

interface SearchTextBoxProps {
  foundResult: boolean;
  isLoading: boolean;
}

interface CertificateCardContentProps {
  isValid: boolean;
}

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div``;

export const Header = styled.header`
  padding: 12px;
  background: #001e46;
`;

export const HeaderContent = styled.div`
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  position: relative;

  color: #ffffff;

  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
  }

  // '>' pq queremos estilizar apenas a primeira img (logo)
  // ou seja, elemento diretamente abaixo do HeaderContent
  > img {
    float: right;
    width: 136px;
    height: auto;
  }

  button {
    display: flex;
    align-items: flex-start;
    background: transparent;
    border: 0;

    svg {
      color: #36a9e0;
      width: 9vw;
      max-width: 50px;
      height: auto;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 710px) {
    h1 {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
    }

    > img {
      content: url(${altrosSymbolNameImg});
      height: 70px;
      width: auto;
      margin: 10px 0;
    }
  }
`;

export const Content = styled.main`
  max-width: 800px;
  margin: -25px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    margin: 115px 0px 100px;

    animation: ${appearFromBottom} 1s;

    text-decoration: none;
    color: #001e46;
    font-size: 16px;

    &:hover {
      color: ${shade(0.2, '#36a9e0')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const SearchTextBox = styled.div<SearchTextBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 75px;

  /* animation: ${fadeIn} 1s; */

  ${props =>
    props.foundResult
      ? css`
          border: 3px solid #95c11e;
        `
      : css`
          border: 3px solid #c31a1b;
        `}

  ${props =>
    props.isLoading &&
    css`
      display: none;
      /* border: 3px solid #f4ede8; */
    `}

  border-radius: 20px;

  background-color: #fff;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.4));

  color: #001e46;
  font-style: normal;
  font-weight: normal;

  h1 {
    font-size: 28px;
  }

  @media screen and (max-width: 710px) {
    padding: 2px 4%;
    /* padding: 15px 25px; */

    h1 {
      font-size: 22px;
    }
  }
`;

export const NotFoundContent = styled.div`
  margin-top: 140px;

  h1 {
    font-size: 28px;
    color: #c31a1b;
  }

  @media screen and (max-width: 410px) {
    h1 {
      font-size: 6vw;
    }
  }
`;

export const CertificateContent = styled.div`
  max-width: 853px;
  display: flex;
  margin-top: 50px;
  background-color: transparent;

  animation: ${appearFromBottom} 1s;

  @media screen and (max-width: 710px) {
    margin-top: 30px;
  }
`;

export const CertificateCardBackground = styled.div`
  display: flex;

  width: 300px;
  height: 475px;
  min-width: 300px;
  max-width: 300px;
  min-height: 475px;

  border-radius: 15px;
  background-color: #fff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
`;

export const CertificateCardContent = styled.div<CertificateCardContentProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 20px 35px;

  border-radius: 15px;
  position: relative;

  h1,
  h3,
  h2,
  p {
    z-index: 10;
  }

  h2 {
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
    margin-left: 30px;
    margin-top: 4px;
  }

  .name {
    color: #001e46;
    font-size: 18px;
    margin-top: 16px;
    text-align: center;
  }

  .emission-date-title,
  .category-title,
  .course-title,
  .valid-date-title {
    font-family: 'Roboto Mono', monospace;
    font-size: 12px;
  }

  .emission-date-title {
    margin-top: 14px;
  }

  .category-title,
  .course-title {
    margin-top: 29.33px;
  }

  .category {
    display: flex;
    max-width: 97px;
    min-height: 42px;
    margin-left: 28px;
    margin-top: 4px;
    align-items: center;

    h2 {
      margin-left: 0px;
      margin-top: 0px;
    }
  }

  .course {
    font-size: 12px;
    max-width: 207px;
    height: auto;
  }

  .course-title,
  .course,
  .valid-date-container {
    color: #fff;
  }

  .valid-date-container {
    position: absolute;
    bottom: 35px;
    z-index: 10;

    h2 {
      color: ${props => !props.isValid && '#FF0100'};
    }
  }

  > svg {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 15px 15px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #ffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  z-index: 10;

  position: absolute;
  left: 56.33%;
  right: 10.33%;
  top: 37.89%;
  bottom: 41.05%;

  svg {
    height: 94px;
    width: auto;
    border-radius: 30px;
  }
`;

export const BackgroundFade = styled.div<BackgroundFadeProps>`
  display: flex;
  width: 600px;
  height: 475px;
  margin-left: -47px;
  z-index: -1;
  overflow: hidden;

  background: linear-gradient(
    0.31deg,
    rgba(
        ${props => hexRgb(props.hex).red},
        ${props => hexRgb(props.hex).green},
        ${props => hexRgb(props.hex).blue},
        0.1
      )
      8.51%,
    rgba(255, 255, 255, 0) 96.67%
  );

  border-radius: 0px 50px 50px 0px;

  img {
    margin-left: 47px;
  }

  @media screen and (max-width: 849px) {
    display: none;
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromBottom} 1s;

  margin-top: 100px;

  h1 {
    font-size: 28px;
    color: #001e46;
  }

  .loader {
    margin-top: 50px;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #36a9e0;
    border-right: 16px solid #95c11e;
    border-bottom: 16px solid #662382;
    border-left: 16px solid #c31a1b;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CertificateCardHeader = styled.div<CertificateCardHeaderProps>`
  flex: 1;
  display: flex;
  align-items: center;
  max-height: 71px;

  h1 {
    color: ${props => `#${props.hex}`};
    font-family: 'Montserrat', sans-serif;
    margin-left: 31px;
    font-size: 22px;
  }

  img {
    height: 71px;
    width: auto;
  }
`;
