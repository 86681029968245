import styled, { css } from 'styled-components';

import ReactDatePicker from 'react-datepicker';

import ToolTip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #e7e7e7;
  border-radius: 10px;
  /* Essa bordar vai fazer sentido no erro. Pois ela será vermelha */
  padding: 16px;
  width: 100%;

  border: 2px solid #e7e7e7;
  color: #606060;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #36a9e0;
      border-color: #36a9e0;
    `}

    color: #36a9e0;

  svg {
    margin-right: 16px;
  }
`;

export const ReactDatePickerElement = styled(ReactDatePicker)`
  /* faz select ocupar todo espaço possivel */
  flex: 1;
  border: 0;
  color: #001e46;
  background: transparent;

  &::placeholder {
    color: #606060;
  }
`;

export const Error = styled(ToolTip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    /* Tbm mudar a setinha do tooltip: */
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
