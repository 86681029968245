export default function getStringDigits(data: string): string {
  const tempData = data.match(/\d+/g)?.reduce((acc, currentDigit) => {
    return acc + currentDigit;
  }, '');

  if (tempData) {
    return tempData;
  }

  return '';
}
