import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';
import { shade } from 'polished';

interface TabButtonrops {
  isSelected: boolean;
}

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  button {
    margin-top: 30px;
  }
`;

export const Menubar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TabButton = styled.button<TabButtonrops>`
  background: transparent;
  border: none;
  color: #001e46;
  width: 100%;
  max-width: 140px;
  font-size: 16px;

  border-bottom: 1px solid #001e46;

  transition: color 0.2s;

  &:hover {
    color: ${shade(0.2, '#36A9E0')};
    border-bottom: 1px solid ${shade(0.2, '#36A9E0')};
  }

  ${props =>
    props.isSelected &&
    css`
      transition: color 0.2s;
      transition: border-bottom 0.2s;

      color: #36a9e0;
      border-bottom: 1px solid #36a9e0;

      &:hover {
        color: #36a9e0;
        border-bottom: 1px solid #36a9e0;
      }
    `}
`;

export const EditCertificateContent = styled.div``;

export const EditAlumniContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  > h1 {
    font-size: 20px;
    color: #001e46;
    margin-bottom: 4px;
    margin-right: auto;
  }
`;

export const WarningMessage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  margin-right: auto;

  p {
    font-family: 'Roboto Mono', monospace;
    color: #999591;
    margin-left: 8px;
    font-size: 12px;
  }

  span {
    font-family: 'Roboto Mono', monospace;
    color: #c31a1b;
    font-size: 12px;
  }
`;

export const AlumniContentTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const DataField = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;

  margin-right: auto;

  span {
    margin-left: 8px;
    color: #001e46;
    font-size: 16px;
  }

  p {
    color: #999591;
    font-size: 16px;
  }

  span + p {
    margin-left: 8px;
  }

  & + div {
    margin-top: 10px;
  }
`;

export const ExcludeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  button {
    background: transparent;
    border: none;
    color: #c31a1b;

    font-size: 16px;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.3, '#C31A1B')};
    }
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 30px;
  right: 30px;

  background: transparent;
  border: none;

  color: #001e46;

  transition: color 0.2s;

  &:hover {
    color: ${shade(0.3, '#36a9e0')};
  }
`;
