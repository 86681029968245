import React from 'react';
import { FiPower } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';

import TopMenu from '../TopMenu';
import { Container, HeaderContent } from './styles';

import logoImg from '../../assets/White-background-logo.png';

interface DashboardHeaderProps {
  selectedMenu: string;
  headerTitle?: string;
  className?: string;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  selectedMenu,
  headerTitle,
  className,
}) => {
  const { signOut } = useAuth();

  return (
    <Container className={className}>
      <HeaderContent>
        <img src={logoImg} alt="Altros" />

        <h1>{headerTitle}</h1>

        <button type="button" onClick={signOut}>
          <FiPower />
          <h2>Logout</h2>
        </button>

        <TopMenu className="top-menu" selectedMenu={selectedMenu} />
      </HeaderContent>
    </Container>
  );
};

export default DashboardHeader;
