import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import WhiteBackgroundLogoImg from '../../assets/White-background-logo.png';

export const Container = styled.div`
  /* Força o Container ter 100% do Viewport Height */
  height: 100vh;

  display: flex;
  /* Faz com que os itens do container tbm tenham tamanho total do Viewport*/
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* O form vai ocuparar até 700px, oq sobrar será da img */
  width: 100%;

  max-width: 768px;

  @media screen and (max-width: 768px) {
    padding: auto;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  animation: ${appearFromRight} 1s;

  form {
    /* display: flex; */
    /* flex-direction: column; */
    margin: 80px 0 0 0;
    max-width: 340px;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 23px;
      & + h1 {
        font-size: 18px;
        margin-bottom: 24px;
        margin-top: 8px;
      }
    }

    @media only screen and (max-height: 670px) and (max-width: 768px) {
      margin: 40px 0 0;
      padding: 0px 10px;

      h1 {
        font-size: 5vw;
        & + h1 {
          font-size: 4vw;
          margin-bottom: 16px;
          margin-top: 4px;
        }
      }
    }
  }

  /* sem '>' esta estilização prejudica a estilização do <a> do form */
  /* utilizamos o '>' para dizer que queremos estilizar SÓ os <a> que estão
     diretamente dentro do meu 'Content' e não também os <a> dos subcomponentes
     do meu 'Content' */
  > a {
    color: #001e46;
    display: block;
    margin-top: 80px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#36A9E0')};
    }

    @media screen and (max-height: 768px) {
      margin-top: 30px;
    }
  }
`;

export const AltrosUniversityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 18px;

  > img {
    height: 135px;
    width: 194px;
    max-width: 194px;

    @media screen and (max-width: 768px) {
      width: 45vw;
      height: auto;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 80px;
    margin-top: 14px;

    img {
      width: 140px;
    }
  }
`;

export const Background = styled.div`
  /* Faz ocupar todo espaço disponivel para ele */
  flex: 1;

  background-image: url(${WhiteBackgroundLogoImg});
  background-repeat: no-repeat;
  background-position: 50% 38.5%;

  background-size: 64%;

  background-color: #001e46;

  @media screen and (max-width: 880px) {
    display: none;
  }
`;

export const EraseDataMessage = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  color: #36a9e0;
  margin-top: 73px;

  font-size: 8px;
  font-weight: 400;
  text-align: center;

  width: 100%;
  /* max-width: 340px; */

  svg {
    margin-right: 5px;
  }

  a {
    text-decoration: none;
    color: #36a9e0;

    transition: color 0.2s;
    &:hover {
      color: ${shade(0.2, '#36A9E0')};
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 6vh;
    font-size: 6px;
  }
`;
