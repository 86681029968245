import React, { useState } from 'react';

import { FiAlertTriangle } from 'react-icons/fi';
import filesize from 'filesize';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';

import DashboardHeader from '../../../Components/DashboardHeader';
import FileList from '../../../Components/FileList';
import Upload from '../../../Components/Upload';
import Button from '../../../Components/Button';

import { Container, Content, ImportFileContainer, Footer } from './styles';

import api from '../../../services/api';

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

const RegisterBatch: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToast();
  const history = useHistory();

  async function handleUpload(): Promise<void> {
    const data = new FormData();

    try {
      setIsLoading(true);

      data.set('certificates_list', uploadedFiles[0].file);
      const response = await api.post('/certificates/import', data);

      setUploadedFiles([]);
      addToast({
        type: 'success',
        title: 'Arquivo processado com sucesso',
      });

      setIsLoading(false);

      history.push({
        pathname: '/search-certificates',
        state: {
          data: JSON.stringify(response.data),
        },
      });
    } catch (err) {
      setIsLoading(false);

      setUploadedFiles([]);

      if (!err.response) {
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no envio do arquivo',
        description: err.response.data.message,
      });
      // console.log(err.response.data.message);
    }
  }

  function submitFile(files: File[]): void {
    // const filesToUpload = files.map(file => ({
    //   file,
    //   name: file.name,
    //   readableSize: filesize(file.size),
    // }));

    // Upload 1 file at a time;
    const filesToUpload = [
      {
        file: files[0],
        name: files[0].name,
        readableSize: filesize(files[0].size),
      },
    ];

    setUploadedFiles(filesToUpload);
  }

  return (
    <Container>
      <DashboardHeader
        selectedMenu="Cadastrar lote"
        headerTitle="Cadastrar lote!"
      />

      <Content>
        <ImportFileContainer>
          <header>Importar arquivos de cadastro</header>
          <Upload onUpload={submitFile} />
          {!!uploadedFiles.length && <FileList files={uploadedFiles} />}

          <Footer>
            <p>
              <FiAlertTriangle size={16} />
              Permitido apenas arquivos CSV
            </p>

            <Button loading={isLoading} onClick={handleUpload} type="button">
              Enviar
            </Button>
          </Footer>
        </ImportFileContainer>
      </Content>
    </Container>
  );
};

export default RegisterBatch;
