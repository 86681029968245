import React from 'react';

interface CertificateBackgroundProps {
  hex: string;
  index: number;
}

const CertificateBackground: React.FC<CertificateBackgroundProps> = ({
  hex,
  index,
}) => {
  return (
    <svg
      width="300"
      height="315"
      viewBox="0 0 300 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 315H300V0.999639C286.5 0.999639 286.5 -0.209202 215.5 12.9997C121.427 30.501 200.276 108.916 122 120C65.5 128 1 105 0 105V315Z"
        fill={`url(#paint${index}_linear)`}
        fillOpacity="0.7"
      />
      <defs>
        <linearGradient
          id={`paint${index}_linear`}
          x1="238.5"
          y1="350"
          x2="238.5"
          y2="-75.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.503004" stopColor={`#${hex}`} />
          <stop offset="1" stopColor="#F4F4F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CertificateBackground;
