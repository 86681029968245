import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';

interface ContainerProps {
  isLoading: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    props.isLoading
      ? css`
          cursor: wait;
        `
      : css`
          cursor: default;
        `}
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  margin-top: 20px;

  align-items: center;
  justify-content: center;
  padding: 30px 60px;

  background-color: #ffffff;
  border: 3px solid #36a9e0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  > h1 {
    font-size: 20px;
    color: #001e46;
    margin-right: auto;
  }
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  button {
    margin-top: 30px;
  }
`;

export const AlumniData = styled.div`
  margin-bottom: 24px;
`;
