import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main`
  max-width: 1120px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const ImportFileContainer = styled.section`
  background: #fff;
  max-width: 728px;
  width: 90vw;
  margin-top: 20px;
  padding: 40px 60px;

  border: 3px solid #36a9e0;
  border-radius: 30px;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  header {
    font-size: 20px;
    color: #001e46;
    margin-bottom: 24px;
  }
`;

export const Footer = styled.section`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  align-items: center;

  p {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: #001e46;

    svg {
      margin-right: 13px;
      color: #c31a1b;
    }
  }

  button {
    width: 80%;
    max-width: 340px;
  }
`;
