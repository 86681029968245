import React, { useCallback, useRef, useState } from 'react';
import { FiPhone, FiCreditCard, FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import getValidationErrors from '../../utils/getValidationErrors';

import graduationHatImg from '../../assets/GraduationHat.svg';
import altrosNameBlueImg from '../../assets/altros-name-blue.png';

// import Input from '../../Components/FormContents/Input';
import InputMask from '../../Components/FormContents/InputMask';
import Button from '../../Components/Button';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  AltrosUniversityContainer,
  EraseDataMessage,
} from './styles';

interface PublicCpfSearchFormData {
  cpf: string | undefined;
}

const PublicCpfSearch: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: PublicCpfSearchFormData) => {
      try {
        setIsLoading(true);

        if (!data.cpf) {
          setIsLoading(false);
          return;
        }

        const formattedData: PublicCpfSearchFormData = data;

        formattedData.cpf = data.cpf
          .match(/\d+/g)
          ?.reduce((acc, currentDigit) => {
            return acc + currentDigit;
          }, '');

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required().min(6, 'Digite os 6 primeiros digitos'),
        });

        await schema.validate(formattedData, {
          abortEarly: false,
        });

        setIsLoading(false);

        history.push(`/search-list?cpf=${formattedData.cpf}`);
      } catch (err) {
        setIsLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na Procura',
          description: 'Ocorreu um erro na procura, tente novamente',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <AltrosUniversityContainer>
            <img src={graduationHatImg} alt="Graduação" />
            <div>
              <h1>Universidade</h1>
              <img src={altrosNameBlueImg} alt="Altros" />
            </div>
          </AltrosUniversityContainer>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Verifique as suas certificações,</h1>
            <h1>insira os 6 primeiros digitos do seu CPF</h1>

            <InputMask
              name="cpf"
              icon={FiCreditCard}
              mask="999.999.\*\*\*\-\*\*"
              placeholder="CPF do Aluno"
            />
            {/* <Input name="cpf" icon={FiCreditCard} placeholder="CPF do Aluno" /> */}

            <Button loading={isLoading} type="submit">
              Procurar
            </Button>
          </Form>
          <a href="https://www.altros.com.br">
            <FiArrowLeft />
            Voltar para o site
          </a>

          <EraseDataMessage>
            <FiPhone size={26} />
            <a href="https://altros.com.br/Contato/">
              <h1>Deseja apagar seus dados do nosso sistema?</h1>
              <h1>Sem problemas, entre em contato conosco</h1>
            </a>
          </EraseDataMessage>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default PublicCpfSearch;
