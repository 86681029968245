import React, { useRef, useCallback, useEffect, useState } from 'react';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import {
  FiList,
  FiCalendar,
  FiEdit,
  FiClock,
  FiWatch,
  FiMapPin,
  FiUser,
  FiCreditCard,
} from 'react-icons/fi';
import { useToast } from '../../../hooks/Toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import getStringDigits from '../../../utils/getStringDigits';

import Input from '../../../Components/FormContents/Input';
import Select from '../../../Components/FormContents/Select';
import DatePicker from '../../../Components/FormContents/Datepicker';
import Textarea from '../../../Components/FormContents/Textarea';
import InputMask from '../../../Components/FormContents/InputMask';
import Button from '../../../Components/Button';

import DashboardHeader from '../../../Components/DashboardHeader';

import { Container, Content, Form, AlumniData, FormContent } from './styles';
import api from '../../../services/api';

import { ICategory } from '../dtos/ApiStructureDTO';

interface ISelectOptions {
  label: string;
  value: string;
}

interface IFormData {
  student: {
    name: string;
    cpf: string;
  };
  emission_date: Date;
  categoryName: string;
  course_description: string;
  months_duration: string;
  course_length_hours: string;
  course_location: string;
}

interface IFormattedFormData
  extends Omit<IFormData, 'months_duration' | 'course_length_hours'> {
  months_duration: number;
  course_length_hours: number;
}

const RegisterAlumni: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [categoriesOptions, seCategoriesOptions] = useState<ISelectOptions[]>(
    [],
  );

  useEffect(() => {
    async function loadCategories(): Promise<void> {
      setIsLoading(true);
      const response = await api.get<ICategory[]>('/category');

      const categoriesList = response.data;

      const options = categoriesList.map(category => {
        return {
          value: String(category.category),
          label: String(category.category),
        };
      });

      seCategoriesOptions(options);
      setIsLoading(false);
    }

    loadCategories();
  }, []);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setIsLoading(true);

        const formattedData: IFormattedFormData = {
          ...data,
          months_duration: 0,
          course_length_hours: 0,
        };

        formattedData.student.cpf = getStringDigits(data.student.cpf);

        if (formattedData.emission_date) {
          formattedData.emission_date = new Date(formattedData.emission_date);
          formattedData.emission_date.setUTCHours(15);
        }

        formattedData.months_duration = Number(
          getStringDigits(data.months_duration),
        );

        formattedData.course_length_hours = Number(
          getStringDigits(data.course_length_hours),
        );

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          student: Yup.object().shape({
            name: Yup.string().required('Digite um nome'),
            cpf: Yup.string().min(11, 'CPF precisa ter 11 digitos'),
          }),
          categoryName: Yup.string().required('Selecione uma categoria'),
          course_description: Yup.string().required('Faltou uma descrição'),
          emission_date: Yup.date().required('Selecione uma data').nullable(),
          course_length_hours: Yup.number()
            .required()
            .min(1, 'Carga horária é obrigatória'),
          course_location: Yup.string(),
          months_duration: Yup.number()
            .required()
            .min(1, 'Duração é obrigatória'),
        });

        await schema.validate(formattedData, {
          abortEarly: false,
        });

        const response = await api.post('/certificates', formattedData);

        const { name, cpf } = response.data.alumni;

        addToast({
          type: 'success',
          title: `Certificado cadastrado com sucesso.`,
          description: `Nome do aluno: ${name}\ncpf: ${cpf}`,
        });

        formRef.current?.clearField('student.name');
        formRef.current?.clearField('student.cpf');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro do certificado',
          description: `Ocorreu um erro ao fazer o cadastro, tente novamente.\n${err.response.data.message}`,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [addToast],
  );

  return (
    <Container isLoading={isLoading}>
      <DashboardHeader
        selectedMenu="Cadastrar aluno"
        headerTitle="Cadastrar aluno!"
      />

      <Content>
        <FormContent>
          <h1>Dados do aluno</h1>

          <Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
            <AlumniData>
              <Input name="student.name" icon={FiUser} placeholder="Nome" />

              <InputMask
                name="student.cpf"
                icon={FiCreditCard}
                mask="999.999.999-99"
                placeholder="CPF"
              />
            </AlumniData>

            <Select
              icon={FiList}
              name="categoryName"
              placeholder="Categoria"
              options={categoriesOptions}
              isClearable
              isSearchable
            />

            <Textarea
              icon={FiEdit}
              name="course_description"
              placeholder="Descrição do curso"
              autoComplete="on"
              rows={5}
            />

            <DatePicker
              icon={FiCalendar}
              name="emission_date"
              placeholderText="Data de emissão"
            />

            <InputMask
              name="months_duration"
              icon={FiClock}
              mask="999 \m\e\s\e\s"
              maskChar=" "
              placeholder="Duração do certificado"
            />

            <InputMask
              name="course_length_hours"
              icon={FiWatch}
              mask="999 \h\o\r\a\s"
              maskChar=" "
              placeholder="Carga horária"
            />

            <Input
              name="course_location"
              icon={FiMapPin}
              autoComplete="on"
              placeholder="Local do curso"
            />

            <Button loading={isLoading} type="submit">
              Cadastrar
            </Button>
          </Form>
        </FormContent>
      </Content>
    </Container>
  );
};

export default RegisterAlumni;
