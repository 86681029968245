import React, { useCallback, useEffect, useState } from 'react';
// import 'react-day-picker/lib/style.css';

import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/Toast';

import {
  Container,
  Header,
  HeaderContent,
  Content,
  SearchTextBox,
  AlumniContent,
  CertificateNumberBox,
  CertificatesNumbersContainer,
  ObtainedCertifications,
  ValidCertifications,
  InvalidCertifications,
  NotFoundContent,
  LoadingContent,
  CertificateContainerBigScreen,
} from './styles';

import altrosNameWhiteImg from '../../assets/White-background-logo.png';

import api from '../../services/api';

interface Category {
  category: string;
  color: string;
  avatar_url: string;
}

interface Certificate {
  id: string;
  emission_date: Date;
  course_description: string;
  months_duration: number;
  category: Category;
}

interface CertificatesCounter {
  valid: number;
  invalid: number;
  total: number;
}

export interface Alumni {
  id: string;
  name: string;
  cpf: string;
  certificates: Certificate[];
  certificates_counter: CertificatesCounter;
}

const PublicSearchList: React.FC = () => {
  const [cpfSearched, setCpfSearched] = useState('');
  const [searchResults, setSearchResults] = useState<Alumni[]>([]);
  const [foundResults, setFoundResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { addToast } = useToast();

  const history = useHistory();

  const goToPage = useCallback(
    (url: string, data?: string) => {
      history.push({
        pathname: url,
        state: {
          data,
        },
      });
    },
    [history],
  );

  useEffect(() => {
    setIsLoading(true);

    const searchTerm = history.location.search.substring(1).split('=');

    if (searchTerm[0] !== 'cpf') {
      setIsLoading(false);
      return;
    }

    setCpfSearched(searchTerm[1]);

    api
      .get<Alumni[]>(`/certificates/cpf-search/${searchTerm[1]}`)
      .then(response => {
        setSearchResults(response.data);
        setIsLoading(false);
      })
      .catch(response => {
        setIsLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no servidor',
          description: `Ocorreu um erro, tente novamente mais tarde`,
        });
      });
  }, [history, addToast]);

  useEffect(() => {
    setFoundResults(!!(searchResults && searchResults.length));
  }, [searchResults]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <button type="button" onClick={() => goToPage('/')}>
            <FiArrowLeft />
          </button>

          <h1>Você pesquisou por:</h1>

          <img src={altrosNameWhiteImg} alt="Altros" />
        </HeaderContent>
      </Header>

      <Content>
        <SearchTextBox isLoading={isLoading} foundResult={foundResults}>
          <h1>
            {`${cpfSearched.substr(0, 3)}.${cpfSearched.substr(3, 3)}.***-**`}
          </h1>
        </SearchTextBox>

        {isLoading && (
          <LoadingContent>
            <h1>Carregando...</h1>
            <div className="loader" />
          </LoadingContent>
        )}

        {!isLoading && !foundResults && (
          <NotFoundContent>
            <h1>Nenhum aluno encontrado</h1>
          </NotFoundContent>
        )}

        {!isLoading &&
          foundResults &&
          searchResults.map(searchResult => (
            <AlumniContent>
              <h1>Nome: {searchResult.name}</h1>
              <h2>
                CPF:{' '}
                {`${cpfSearched.substr(0, 3)}.${cpfSearched.substr(
                  3,
                  3,
                )}.***-**`}
              </h2>

              <CertificatesNumbersContainer>
                <div className="total-cert-small-screen">
                  <CertificateNumberBox>
                    <ObtainedCertifications>
                      Cert. obtidas:{' '}
                      <span>{searchResult.certificates_counter.total}</span>
                    </ObtainedCertifications>
                  </CertificateNumberBox>
                </div>

                <CertificateContainerBigScreen>
                  <CertificateNumberBox className="total-cert-big-screen">
                    <ObtainedCertifications>
                      Cert. obtidas:{' '}
                      <span>{searchResult.certificates_counter.total}</span>
                    </ObtainedCertifications>
                  </CertificateNumberBox>

                  <CertificateNumberBox>
                    <ValidCertifications>
                      Cert. válidas:{' '}
                      <span>{searchResult.certificates_counter.valid}</span>
                    </ValidCertifications>
                  </CertificateNumberBox>
                  <CertificateNumberBox className="invalid-cert">
                    <InvalidCertifications>
                      Cert. vencidas:{' '}
                      <span>{searchResult.certificates_counter.invalid}</span>
                    </InvalidCertifications>
                  </CertificateNumberBox>
                </CertificateContainerBigScreen>
              </CertificatesNumbersContainer>

              <button
                type="button"
                onClick={() =>
                  goToPage('/certifications', JSON.stringify(searchResult))
                } // eslint-disable-line
              >
                Clique aqui e saiba mais
              </button>
            </AlumniContent>
          ))}
        <Link to="/">
          <FiArrowLeft size={20} />
          Voltar para pesquisa
        </Link>
      </Content>
    </Container>
  );
};

export default PublicSearchList;
