import { shade } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import AlumniBigScreenCardBgImg from '../../assets/AlumniList/AlumnicardBackGround.svg';

import AlumniSmallScreenCardBgImg from '../../assets/AlumniList/AlumnicardSmllScreenBackGround.svg';

import altrosSymbolNameImg from '../../assets/altros-symbol-name.png';

interface SearchTextBoxProps {
  foundResult: boolean;
  isLoading: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div``;

export const Header = styled.header`
  padding: 12px;
  background: #001e46;
  animation: ${appearFromTop} 1s;
`;

export const HeaderContent = styled.div`
  display: flex;
  max-width: 1120px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  position: relative;

  color: #ffffff;

  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 26px;
  }

  // '>' pq queremos estilizar apenas a primeira img (logo)
  // ou seja, elemento diretamente abaixo do HeaderContent
  > img {
    float: right;
    width: 136px;
    height: auto;
  }

  button {
    display: flex;
    align-items: flex-start;
    background: transparent;
    border: 0;

    svg {
      color: #36a9e0;
      width: 9vw;
      max-width: 50px;
      height: auto;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 710px) {
    h1 {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
    }

    > img {
      content: url(${altrosSymbolNameImg});
      height: 70px;
      width: auto;
      margin: 10px 0;
    }
  }
`;

export const Content = styled.main`
  max-width: 800px;
  margin: -25px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    margin: 115px 0px 100px;

    animation: ${appearFromBottom} 1s;

    text-decoration: none;
    color: #001e46;
    font-size: 16px;

    &:hover {
      color: ${shade(0.2, '#36a9e0')};
    }

    svg {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 710px) {
    padding: 0px 10px;
  }
`;

export const SearchTextBox = styled.div<SearchTextBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px 80px;

  animation: ${appearFromTop} 1s;

  ${props =>
    props.foundResult
      ? css`
          border: 3px solid #95c11e;
        `
      : css`
          border: 3px solid #c31a1b;
        `}

  ${props =>
    props.isLoading &&
    css`
      border: 3px solid #f4ede8;
    `}

  border-radius: 20px;

  background-color: #fff;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.4));

  h1 {
    color: #001e46;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
  }

  @media screen and (max-width: 710px) {
    padding: 15px 25px;
    h1 {
      font-size: 22px;
    }
  }
`;

export const AlumniContent = styled.div`
  display: flex;
  max-width: 800px;
  width: 100%;

  flex-direction: column;

  animation: ${appearFromBottom} 1s;

  background-color: #f4ede8;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 60px;

  background: url(${AlumniBigScreenCardBgImg}) no-repeat top;

  padding: 26px 56px;
  margin-top: 30px;

  color: #001e46;

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
    margin-top: 46px;
    margin-left: 14px;
  }

  button {
    background: transparent;
    border: 0;
    margin: auto;
    margin-top: 29px;
    color: #001e46;
    font-size: 22px;
    &:hover {
      color: ${shade(0.2, '#36a9e0')};
    }
  }

  @media screen and (max-width: 850px) {
    width: 90vw;
  }

  @media screen and (max-width: 530px) {
    background: url(${AlumniSmallScreenCardBgImg}) no-repeat top;
    background-size: 100%;
  }

  @media screen and (max-width: 530px) {
    border-radius: 40px;
    padding: 30px 20px 25px;

    h1 {
      font-size: 15px;
      margin-left: 5px;
    }

    h2 {
      font-size: 15px;
      margin-top: 50px;
      margin-left: 5px;
    }

    button {
      margin-top: 25px;
      font-size: 15px;
    }
  }
`;

export const CertificatesNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* margin: 0 14px; */

  .total-cert-big-screen {
    display: block;
  }

  .total-cert-small-screen {
    display: none;
  }

  @media screen and (max-width: 710px) {
    flex-direction: column;
    margin: 20px -10px 0px;

    .total-cert-big-screen {
      display: none;
    }

    .total-cert-small-screen {
      display: block;
    }
  }
`;

export const CertificateContainerBigScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 22px;

  @media screen and (max-width: 710px) {
    margin-top: 12px;
  }
`;

export const CertificateNumberBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #001e46;

  padding: 18px 19px;
  max-width: 200px;
  width: 100%;

  border-radius: 25px;

  color: #ffffff;

  p {
    font-size: 19px;
  }

  span {
    margin-left: 8px;
  }

  @media screen and (max-width: 710px) {
    width: 146px;

    padding: 7.5px 10px;

    p {
      font-size: 14px;
    }

    span {
      margin-left: 5px;
    }
  }

  /* & + div {
    margin-left: 30px;
  } */

  /* @media screen and (max-width: 767px) {
    p {
      font-size: 90%;
    }
  }

  @media screen and (max-width: 610px) {
    p {
      font-size: 60%;
    }
  } */
`;

export const ObtainedCertifications = styled.p`
  span {
    color: #36a9e0;
  }
`;

export const ValidCertifications = styled.p`
  span {
    color: #95c11e;
  }
`;

export const InvalidCertifications = styled.p`
  span {
    color: #c31a1b;
  }
`;

export const NotFoundContent = styled.div`
  margin-top: 140px;

  h1 {
    font-size: 28px;
    color: #c31a1b;
  }

  @media screen and (max-width: 410px) {
    h1 {
      font-size: 6vw;
    }
  }
`;

export const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromBottom} 1s;

  margin-top: 100px;

  h1 {
    font-size: 28px;
    color: #001e46;
  }

  .loader {
    margin-top: 50px;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #36a9e0;
    border-right: 16px solid #95c11e;
    border-bottom: 16px solid #662382;
    border-left: 16px solid #c31a1b;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
