import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, MenuItem } from './styles';

interface TopMenuProps {
  selectedMenu: string;
  className?: string;
}

const TopMenu: React.FC<TopMenuProps> = ({ selectedMenu, className }) => {
  const menuList = useMemo(
    () => [
      ['Cadastrar aluno', '/register-alumni'],
      ['Cadastrar lote', '/register-batch'],
      ['Pesquisar', '/search-certificates'],
      ['Criar conta', '/signup'],
    ],
    [],
  );

  const selectedMenuItem = useMemo(() => {
    const itemIndex = menuList.findIndex(
      menuItem => menuItem[0] === selectedMenu,
    );

    if (!itemIndex) {
      return 0;
    }

    return itemIndex;
  }, [menuList, selectedMenu]);

  const history = useHistory();

  return (
    <Container className={className}>
      <ul>
        {menuList.map((menuItem, index) => (
          <li key={menuItem[0]}>
            <MenuItem
              type="button"
              onClick={() => {
                history.push(menuItem[1]);
              }}
              isSelected={index === selectedMenuItem}
            >
              <p>{menuItem[0]}</p>
              <div className="border" />
            </MenuItem>
            {/* </button> */}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default TopMenu;
