import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import download from 'downloadjs';

import { FiCreditCard, FiSearch, FiEdit } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import ICertificate, {
  IAlumni,
  IAlumniCertificates,
  ICategory,
} from '../dtos/ApiStructureDTO';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../Components/FormContents/Input';

import Button from '../../../Components/Button';

import { useToast } from '../../../hooks/Toast';

import DashboardHeader from '../../../Components/DashboardHeader';
import ModalEditDeleteCertificate from '../../../Components/ModalEditDeleteCertificate';

import {
  Container,
  Content,
  ListOrder,
  OrderButton,
  Certificate,
  EditButton,
  DataRow,
  DataField,
} from './styles';

import api from '../../../services/api';

interface HistoryProps {
  data?: string;
}

interface SearchFormData {
  cpfList: string;
}

interface ISelectOptions {
  label: string;
  value: string;
}

const SearchCertificates: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrderButton, setSelectedOrderButton] = useState(
    'emission_date',
  );
  const [orderedCertificateList, setOrderedCertificateList] = useState<
    ICertificate[]
  >([]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingCertificate, setEditingCertificate] = useState<ICertificate>(
    {} as ICertificate,
  );
  const [editCategoriesOptions, setEditCategoriesOptions] = useState<
    ISelectOptions[]
  >([]);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const location = useLocation<HistoryProps>();
  const history = useHistory<HistoryProps>();

  useEffect(() => {
    setIsLoading(true);

    if (
      location.state &&
      location.state.data &&
      location.state.data.length !== 0
    ) {
      const compoundCertificateList = JSON.parse(
        location.state.data,
      ) as ICertificate[];

      compoundCertificateList.sort((a, b) => {
        return (
          new Date(a.emission_date).getTime() -
          new Date(b.emission_date).getTime()
        );
      });

      setOrderedCertificateList(compoundCertificateList);

      const state = { ...history.location.state };
      delete state.data;
      history.replace({ ...history.location, state });
    }

    setIsLoading(false);
  }, [location.state, history]);

  const handleOrderByEmissionDate = useCallback(
    (certificateList: ICertificate[]) => {
      setSelectedOrderButton('emission_date');

      const tempCertificateList = certificateList;

      tempCertificateList.sort((a, b) => {
        return (
          new Date(b.emission_date).getTime() -
          new Date(a.emission_date).getTime()
        );
      });

      setOrderedCertificateList([...tempCertificateList]);
    },
    [],
  );

  const handleOrderByName = useCallback((certificateList: ICertificate[]) => {
    setSelectedOrderButton('name');

    const tempCertificateList = certificateList;

    tempCertificateList.sort((a, b) => {
      if (a.alumni.name < b.alumni.name) {
        return -1;
      }
      if (a.alumni.name > b.alumni.name) {
        return 1;
      }
      return 0;
    });

    setOrderedCertificateList([...tempCertificateList]);
  }, []);

  const handleSubmit = useCallback(
    async (data: SearchFormData) => {
      setIsLoading(true);

      setOrderedCertificateList([]);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cpfList: Yup.string().required('Digite a lista de CPFs'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const cpfList = data.cpfList.split(/\s/g).join('').split(',');

        const response = await api.get<IAlumniCertificates[]>(
          '/certificates/cpf-list',
          {
            params: {
              cpf_list: encodeURIComponent(JSON.stringify(cpfList)),
            },
          },
        );

        if (response.data.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhum resultado encontrado',
            description: 'Nenhum dos CPFs da lista foi encontrado',
          });

          return;
        }

        const compoundCertificateList = response.data.reduce(
          (acc1: ICertificate[], AlumniCertificates: IAlumniCertificates) => {
            const partialCertificatesArray = AlumniCertificates.certificates.reduce(
              (acc2: ICertificate[], certificate: ICertificate) => {
                const newCertificate = certificate;

                Object.assign(newCertificate, {
                  ...certificate,
                  alumni: AlumniCertificates as IAlumni,
                });

                acc2.push(newCertificate);
                return acc2;
              },
              [] as ICertificate[],
            );

            partialCertificatesArray.forEach(certificate =>
              acc1.push(certificate),
            );

            return acc1;
          },
          [] as ICertificate[],
        );

        if (compoundCertificateList.length === 0) {
          addToast({
            type: 'error',
            title: 'Certificado nao encontrado',
            description:
              'O aluno foi encontrado, porém ele nao possui nenhum certificado cadastrado',
          });

          return;
        }

        if (selectedOrderButton === 'name') {
          handleOrderByName(compoundCertificateList);
        } else {
          handleOrderByEmissionDate(compoundCertificateList);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro busca',
          description: 'Ocorreu um erro ao fazer a busca, tente novamente.',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      addToast,
      handleOrderByEmissionDate,
      selectedOrderButton,
      handleOrderByName,
    ],
  );

  const handleDownloadCertificate = useCallback(
    async (id: string) => {
      try {
        const response = await api.get(`/certificates/${id}/certificate-pdf`, {
          responseType: 'blob',
        });

        download(
          response.data,
          'pdf-lib_creation_example.pdf',
          'application/pdf',
        );
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na geração do certificado',
          description: 'Ocorreu um erro na geração do certificado',
        });
      }
    },
    [addToast],
  );

  const toggleEditModal = useCallback(() => {
    setEditModalOpen(!editModalOpen);
  }, [editModalOpen]);

  const handleEditCertificate = useCallback(
    (certificate: ICertificate) => {
      async function loadDataAndToggleModal(): Promise<void> {
        setIsLoading(true);
        const response = await api.get<ICategory[]>('/category');

        const categoriesList = response.data;

        const options = categoriesList.map(category => {
          return {
            value: String(category.category),
            label: String(category.category),
          };
        });

        setEditCategoriesOptions(options);
        setEditingCertificate(certificate);

        setIsLoading(false);

        toggleEditModal();
      }

      loadDataAndToggleModal();
    },
    [toggleEditModal],
  );

  const handleCleanCertificateList = useCallback(() => {
    setOrderedCertificateList([]);
  }, []);

  const cpfStringMask = useCallback((cpf: string) => {
    return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(
      6,
      3,
    )}-${cpf.substr(9, 2)}`;
  }, []);

  const formattedEmissionDate = useCallback((emissionDate: Date) => {
    const formattedDate = new Date(emissionDate);
    formattedDate.setUTCHours(15);

    return format(formattedDate, 'dd/MM/yyyy');
  }, []);

  return (
    <Container isLoading={isLoading}>
      <DashboardHeader
        selectedMenu="Pesquisar"
        headerTitle="Pesquisar certificado!"
      />
      <ModalEditDeleteCertificate
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        editingCertificate={editingCertificate}
        categoriesOptions={editCategoriesOptions}
        cleanCertificateList={handleCleanCertificateList}
      />

      <Content isLoading={isLoading}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="input-div">
            <Input
              name="cpfList"
              icon={FiCreditCard}
              placeholder="Lista de CPFs"
            />
            <p>*Colocar os CPFs separados por virgula, sem pontos ou traços</p>
          </div>

          <Button type="submit">
            <FiSearch size={27} />
          </Button>
        </Form>

        <ListOrder>
          <h1>Ordernar por:</h1>
          <OrderButton
            type="button"
            onClick={() => {
              handleOrderByEmissionDate(orderedCertificateList);
            }}
            isSelected={selectedOrderButton === 'emission_date'}
          >
            Data de emissão
          </OrderButton>
          <OrderButton
            type="button"
            onClick={() => {
              handleOrderByName(orderedCertificateList);
            }}
            isSelected={selectedOrderButton === 'name'}
          >
            Nome
          </OrderButton>
        </ListOrder>

        {orderedCertificateList.map(certificate => (
          <Certificate key={certificate.id}>
            <EditButton
              onClick={() => {
                handleEditCertificate(certificate);
              }}
            >
              <FiEdit size={20} />
              <span>Editar</span>
            </EditButton>

            <DataRow>
              <DataField>
                <p>Nome:</p>
                <span>{certificate.alumni.name}</span>
              </DataField>

              <DataField>
                <p>CPF:</p>
                <span>{cpfStringMask(certificate.alumni.cpf)}</span>
              </DataField>
            </DataRow>

            <DataRow>
              <DataField>
                <p>Data Emissão:</p>
                <span>{formattedEmissionDate(certificate.emission_date)}</span>
              </DataField>

              <DataField>
                <p>Duração:</p>
                <span>{certificate.months_duration}</span>
                <p>meses</p>
              </DataField>
            </DataRow>

            <DataRow>
              <DataField>
                <p>Categoria:</p>
                <span>{certificate.category.category}</span>
              </DataField>

              <DataField>
                <p>Carga horária</p>
                <span>{certificate.course_length_hours}</span>
                <p>horas</p>
              </DataField>
            </DataRow>

            <DataRow>
              <DataField>
                <p>Local:</p>
                <span>{certificate.course_location}</span>
              </DataField>
            </DataRow>

            <DataRow>
              <DataField>
                <p>Curso:</p>
                <span>{certificate.course_description}</span>
              </DataField>
            </DataRow>

            <button
              className="download-btn"
              type="button"
              onClick={() => {
                handleDownloadCertificate(certificate.id);
              }}
            >
              Baixar certificado!
            </button>
          </Certificate>
        ))}
      </Content>
    </Container>
  );
};

export default SearchCertificates;
