import React, { useState, useEffect } from 'react';

import { StyledModal, Container } from './styles';

interface IModalProps {
  children: any;
  isOpen: boolean;
  setIsOpen: () => void;
  contentLabel: string;
  onClose?: () => void;
  className: string;
  tall?: boolean;
  noCloseLabel?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  className = '',
  tall = false,
  noCloseLabel = false,
  contentLabel,
  onClose,
  ...props
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <StyledModal
      shouldCloseOnOverlayClick={!false}
      isOpen={modalStatus}
      contentLabel={contentLabel}
      onRequestClose={setIsOpen}
      className={className}
      ariaHideApp={false}
      tall={tall}
      {...props}
    >
      <Container className={className}>{children}</Container>
    </StyledModal>
  );
};

export default Modal;
