import React, { useRef, useCallback, useMemo, useState } from 'react';

import * as Yup from 'yup';

import {
  FiX,
  FiList,
  FiCalendar,
  FiEdit,
  FiClock,
  FiWatch,
  FiMapPin,
  FiUser,
  FiCreditCard,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';
import getStringDigits from '../../utils/getStringDigits';

import {
  Form,
  Menubar,
  AlumniContentTitle,
  EditCertificateContent,
  EditAlumniContent,
  WarningMessage,
  DataField,
  ExcludeButton,
  TabButton,
  CloseButton,
} from './styles';
import Modal from '../Modal';
import Input from '../FormContents/Input';
import Select from '../FormContents/Select';
import DatePicker from '../FormContents/Datepicker';
import Textarea from '../FormContents/Textarea';
import InputMask from '../FormContents/InputMask';
import Button from '../Button';

import ICertificate from '../../pages/Private/dtos/ApiStructureDTO';
import api from '../../services/api';

interface ISelectOptions {
  label: string;
  value: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  cleanCertificateList: () => void;
  editingCertificate: ICertificate;
  categoriesOptions: ISelectOptions[];
}

interface IEditCertificateData {
  emission_date: string;
  categoryName: string;
  course_description: string;
  months_duration: string;
  course_length_hours: string;
  course_location: string;
}

interface IEditAlumniData {
  name: string;
  cpf: string;
}

const ModalEditDeleteCertificate: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  cleanCertificateList,
  categoriesOptions,
  editingCertificate,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('certification');

  const certificateFormRef = useRef<FormHandles>(null);
  const alumniFormRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleEditCertificate = useCallback(
    async (data: IEditCertificateData) => {
      try {
        const { categoryName, course_description, course_location } = data;

        const newEmissionDate = new Date(data.emission_date);
        newEmissionDate.setUTCHours(15);

        const newMonthsDuration = Number(getStringDigits(data.months_duration));

        const newCourseLengthHours = Number(
          getStringDigits(data.course_length_hours),
        );

        await api.put(`/certificates/${editingCertificate.id}`, {
          emission_date: newEmissionDate,
          categoryName,
          course_description,
          months_duration: newMonthsDuration,
          course_length_hours: newCourseLengthHours,
          course_location,
        });

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        cleanCertificateList();
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na atualização do certificado',
          description: `Ocorreu um erro ao fazer as atualizações, tente novamente.\n${err.response.data.message}`,
        });
      } finally {
        setIsOpen();
      }
    },
    [setIsOpen, addToast, editingCertificate, cleanCertificateList],
  );

  const handleDeleteCertificate = useCallback(async () => {
    try {
      await api.delete(`/certificates/${editingCertificate.id}`);

      addToast({
        type: 'success',
        title: 'Certificado deletado com sucesso!',
      });

      cleanCertificateList();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar o certificado',
        description: `Ocorreu um erro ao deletar o certificado, tente novamente.\n${err.response.data.message}`,
      });
    } finally {
      setIsOpen();
    }
  }, [setIsOpen, addToast, editingCertificate, cleanCertificateList]);

  const handleEditAlumni = useCallback(
    async (data: IEditAlumniData) => {
      try {
        const { name } = data;

        const cpf = getStringDigits(data.cpf);

        const dataToValidate = {
          name,
          cpf,
        };

        alumniFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string(),
          cpf: Yup.string().min(11, 'CPF precisa ter 11 digitos'),
        });

        await schema.validate(dataToValidate, {
          abortEarly: false,
        });

        await api.put(
          `/alumni/${editingCertificate.alumni.id}`,
          dataToValidate,
        );

        addToast({
          type: 'success',
          title: 'Atualização realizada com sucesso',
        });

        cleanCertificateList();

        setIsOpen();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          alumniFormRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização do aluno',
          description: `Ocorreu um erro ao fazer as atualizações, tente novamente.\n${err.response.data.message}`,
        });
        setIsOpen();
      }
    },
    [setIsOpen, addToast, editingCertificate, cleanCertificateList],
  );

  const handleDeleteAlumni = useCallback(async () => {
    try {
      await api.delete(`/alumni/${editingCertificate.alumni.id}`);

      addToast({
        type: 'success',
        title: 'Aluno deletado com sucesso!',
      });

      cleanCertificateList();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar o aluno',
        description: `Ocorreu um erro ao deletar o aluno, tente novamente.\n${err.response.data.message}`,
      });
    } finally {
      setIsOpen();
    }
  }, [setIsOpen, addToast, editingCertificate, cleanCertificateList]);

  const cpfStringMask = useMemo(() => {
    if (editingCertificate.alumni) {
      return `${editingCertificate.alumni.cpf.substr(
        0,
        3,
      )}.${editingCertificate.alumni.cpf.substr(
        3,
        3,
      )}.${editingCertificate.alumni.cpf.substr(
        6,
        3,
      )}-${editingCertificate.alumni.cpf.substr(9, 2)}`;
    }
    return '';
  }, [editingCertificate.alumni]);

  return (
    <Modal
      contentLabel="EditModal"
      className="EditModal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Menubar>
        <TabButton
          isSelected={selectedTab === 'certification'}
          onClick={() => {
            setSelectedTab('certification');
          }}
        >
          Certificação
        </TabButton>
        <TabButton
          isSelected={selectedTab === 'alumni'}
          onClick={() => {
            setSelectedTab('alumni');
          }}
        >
          Perfil Aluno
        </TabButton>
      </Menubar>

      {editingCertificate.category && selectedTab === 'certification' && (
        <EditCertificateContent>
          <AlumniContentTitle>
            <DataField>
              <p>Nome:</p>
              <span>
                {editingCertificate.alumni && editingCertificate.alumni.name}
              </span>
            </DataField>
            <DataField>
              <p>CPF:</p>
              <span>{cpfStringMask}</span>
            </DataField>
          </AlumniContentTitle>

          <Form
            ref={certificateFormRef}
            onSubmit={handleEditCertificate}
            initialData={editingCertificate}
          >
            <Select
              icon={FiList}
              name="categoryName"
              placeholder="Categoria"
              options={categoriesOptions}
              defaultValue={categoriesOptions.find(
                category =>
                  category.label === editingCertificate.category.category,
              )}
              isClearable
              isSearchable
            />

            <Textarea
              icon={FiEdit}
              name="course_description"
              placeholder="Descrição do curso"
              rows={5}
            />

            <DatePicker
              icon={FiCalendar}
              name="emission_date"
              placeholderText="Data de emissão"
            />

            <InputMask
              name="months_duration"
              icon={FiClock}
              mask="999 \m\e\s\e\s"
              maskChar=" "
              placeholder="Duração do certificado"
            />

            <InputMask
              name="course_length_hours"
              icon={FiWatch}
              mask="999 \h\o\r\a\s"
              maskChar=" "
              placeholder="Carga horária"
            />

            <Input
              name="course_location"
              icon={FiMapPin}
              placeholder="Local do curso"
            />

            <Button type="submit">Editar</Button>
          </Form>

          <ExcludeButton>
            <button type="button" onClick={handleDeleteCertificate}>
              Excluir Certificação
            </button>
          </ExcludeButton>
        </EditCertificateContent>
      )}

      {editingCertificate.category && selectedTab === 'alumni' && (
        <EditAlumniContent>
          <h1>Dados do aluno</h1>

          <Form
            ref={alumniFormRef}
            onSubmit={handleEditAlumni}
            initialData={editingCertificate.alumni}
          >
            <Input name="name" icon={FiUser} placeholder="Nome" />

            <InputMask
              name="cpf"
              icon={FiCreditCard}
              mask="999.999.999-99"
              placeholder="CPF"
            />

            <Button type="submit">Editar</Button>
          </Form>
          <WarningMessage>
            <p>
              <span>CUIDADO:</span> Ao alternar os dados do aluno, todos os
              certificados dele serão atrelados aos novos dados
            </p>
          </WarningMessage>

          <ExcludeButton>
            <button type="button" onClick={handleDeleteAlumni}>
              Excluir Aluno
            </button>
          </ExcludeButton>
          <WarningMessage>
            <p>
              <span>CUIDADO:</span> Excluir o aluno também excluíra suas
              certificações do banco de dados.
            </p>
          </WarningMessage>
        </EditAlumniContent>
      )}

      <CloseButton onClick={setIsOpen}>
        <FiX size={24} />
      </CloseButton>
    </Modal>
  );
};

export default ModalEditDeleteCertificate;
