import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Routes';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
// import Dashboard from '../pages/Private/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
// import Profile from '../pages/Private/Profile';
import RegisterAlumni from '../pages/Private/RegisterAlumni';
import RegisterBatch from '../pages/Private/RegisterBatch';
import SearchCertificates from '../pages/Private/SearchCertificates';

import PublicCpfSearch from '../pages/PublicCpfSearch';
import PublicSearchList from '../pages/PublicSearchList';
import Certifications from '../pages/Certifications';

const Router: React.FC = () => (
  <Switch>
    <Route path="/" exact component={PublicCpfSearch} />
    <Route path="/login" component={SignIn} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/search-list" component={PublicSearchList} />
    <Route path="/certifications" component={Certifications} />

    {/* <Route path="/profile" component={Profile} isPrivate /> */}
    {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}
    <Route path="/signup" component={SignUp} isPrivate />
    <Route path="/register-alumni" component={RegisterAlumni} isPrivate />
    <Route path="/register-batch" component={RegisterBatch} isPrivate />
    <Route
      path="/search-certificates"
      component={SearchCertificates}
      isPrivate
    />
  </Switch>
);

export default Router;
