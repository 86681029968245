import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { addMonths, isPast, format } from 'date-fns';

import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  Container,
  Header,
  HeaderContent,
  Content,
  SearchTextBox,
  LoadingContent,
  CertificateContent,
  CertificateCardContent,
  BackgroundFade,
  CertificateCardBackground,
  CertificateCardHeader,
  AvatarContainer,
  NotFoundContent,
} from './styles';

import CertificateBackground from '../../Components/CertificateBackground';
import CertificateAvatarImg from '../../Components/CertificateAvatar';
import altrosWhiteLogoImg from '../../assets/White-background-logo.png';
import altrosBlueLogoImg from '../../assets/Blue-background-logo-no-name.png';

import { Alumni } from '../PublicSearchList';

interface HistoryProps {
  data: string;
}

const Certifications: React.FC = () => {
  const [alumni, setAlumni] = useState<Alumni>();
  const [isLoading, setIsLoading] = useState(true);
  const [foundResults, setFoundResults] = useState(false);

  const history = useHistory();

  const location = useLocation<HistoryProps>();

  useEffect(() => {
    setIsLoading(true);

    const alumniDatesCorrection = JSON.parse(location.state.data) as Alumni;

    const certificatesWithDateCorrection = alumniDatesCorrection.certificates.map(
      certificate => {
        const dateWithHours = new Date(certificate.emission_date);
        dateWithHours.setUTCHours(5);

        return {
          ...certificate,
          emission_date: dateWithHours,
        };
      },
    );

    certificatesWithDateCorrection.sort((a, b) => {
      return (
        new Date(b.emission_date).getTime() -
        new Date(a.emission_date).getTime()
      );
    });

    alumniDatesCorrection.certificates = certificatesWithDateCorrection;

    setAlumni(alumniDatesCorrection);
    setFoundResults(!!alumniDatesCorrection.certificates.length);
    setIsLoading(false);
  }, [location.state.data]);

  const isCertificateValid = useCallback(
    (emissionDate: Date, monthsDuration: number) => {
      return !isPast(addMonths(emissionDate, monthsDuration));
    },
    [],
  );

  const formatDate = useCallback(date => {
    return format(date, 'dd/MM/yyyy');
  }, []);

  const fortmatedName = useMemo(() => {
    if (!alumni) {
      return '';
    }

    const nameTokens = alumni.name.split(' ');

    if (nameTokens.length > 1) {
      return `${nameTokens[0]} ${nameTokens[nameTokens.length - 1]}`;
    }

    return nameTokens[0];
  }, [alumni]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <button type="button" onClick={() => history.goBack()}>
            <FiArrowLeft size={50} />
          </button>

          <h1>Certificações de:</h1>

          <img src={altrosWhiteLogoImg} alt="Altros" />
        </HeaderContent>
      </Header>

      <Content>
        <SearchTextBox isLoading={isLoading} foundResult={foundResults}>
          <h1>{fortmatedName}</h1>
          <h1>
            {`${alumni?.cpf.substr(0, 3)}.${alumni?.cpf.substr(3, 3)}.***-**`}
          </h1>
        </SearchTextBox>

        {isLoading && (
          <LoadingContent>
            <h1>Carregando...</h1>
            <div className="loader" />
          </LoadingContent>
        )}

        {!isLoading && !foundResults && (
          <NotFoundContent>
            <h1>Nenhum certificado encontrado</h1>
          </NotFoundContent>
        )}

        {!isLoading &&
          !!alumni &&
          foundResults &&
          alumni.certificates.map((certificate, index) => (
            <CertificateContent key={certificate.id}>
              <CertificateCardBackground>
                <CertificateCardContent
                  isValid={isCertificateValid(
                    certificate.emission_date,
                    certificate.months_duration,
                  )}
                >
                  <CertificateCardHeader hex={certificate.category.color}>
                    <img src={altrosBlueLogoImg} alt="Altros" />
                    <h1>Certificação</h1>
                  </CertificateCardHeader>

                  <h1 className="name">{fortmatedName}</h1>

                  <p className="emission-date-title">Data emissão</p>
                  <h2>{formatDate(certificate.emission_date)}</h2>

                  <p className="category-title">Categoria</p>
                  <div className="category">
                    <h2>{certificate.category.category}</h2>
                  </div>

                  <p className="course-title">Curso</p>
                  <h2 className="course">{certificate.course_description}</h2>

                  <div className="valid-date-container">
                    <p className="valid-date-title">Data de Validade</p>
                    <h2>
                      {formatDate(
                        addMonths(
                          certificate.emission_date,
                          certificate.months_duration,
                        ),
                      )}
                    </h2>
                  </div>

                  <AvatarContainer>
                    <CertificateAvatarImg hex={certificate.category.color} />
                  </AvatarContainer>

                  <CertificateBackground
                    hex={certificate.category.color}
                    index={index}
                  />
                </CertificateCardContent>
              </CertificateCardBackground>

              <BackgroundFade hex={certificate.category.color}>
                <img src={certificate.category.avatar_url} alt="machine" />
              </BackgroundFade>
            </CertificateContent>
          ))}

        <Link to="/">
          <FiArrowLeft size={20} />
          Voltar para pesquisa
        </Link>
      </Content>
    </Container>
  );
};

export default Certifications;
