import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface OrderButtonProps {
  isSelected: boolean;
}

interface ContainerProps {
  isLoading: boolean;
}

interface ContentProps {
  isLoading: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    props.isLoading
      ? css`
          cursor: wait;
        `
      : css`
          cursor: default;
        `}
`;

export const Content = styled.main<ContentProps>`
  width: 90vw;
  max-width: 562px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;

  align-items: center;

  form {
    width: 100%;
    display: flex;

    justify-content: space-between;
    margin-top: 30px;

    p {
      font-family: 'Roboto Mono', monospace;
      font-size: 12px;
      color: #999591;
      width: 100%;
      margin-top: 10px;
    }

    @media screen and (min-width: 405px) {
      button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 56px;
        height: 56px;
        border-radius: 50%;

        margin-left: 30px;
        margin-top: 0px;

        ${props =>
          props.isLoading
            ? css`
                cursor: wait;
              `
            : css`
                cursor: pointer;
              `}
      }
    }

    .input-div {
      width: 100%;
      /* max-width: 456px; */
    }

    @media screen and (max-width: 405px) {
      width: 100%;
      display: flex;
      flex-direction: column;

      justify-content: space-between;
      margin-top: 30px;
    }
  }
`;

export const ListOrder = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  margin-top: 20px;

  h1 {
    font-family: 'Roboto Mono', monospace;
    font-size: 16px;
  }
`;

export const OrderButton = styled.button<OrderButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  color: #999591;

  font-size: 16px;

  margin-left: 8px;

  transition: color 0.2s;

  &:hover {
    color: ${shade(0.3, '#999591')};
  }

  ${props =>
    props.isSelected &&
    css`
      color: #36a9e0;

      &:hover {
        color: #36a9e0;
      }
    `}
`;

export const Certificate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 30px 30px;
  position: relative;
  margin-top: 20px;

  max-width: 562px;
  width: 100%;
  box-sizing: border-box;
  /* height: 298px; */

  background: #fff;
  border-radius: 30px;
  border: 3px solid #36a9e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;

    background: transparent;
    border: none;

    font-size: 16px;
    color: #36a9e0;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.3, '#36a9e0')};
    }
  }

  &:last-child {
    margin-bottom: 50px;
  }
`;

export const DataRow = styled.div`
  display: flex;

  & + div {
    margin-top: 20px;
  }
`;

export const DataField = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;

  & + div {
    margin-left: auto;
  }

  span {
    margin-left: 8px;
    color: #001e46;
    font-size: 16px;
  }

  p {
    color: #999591;
    font-size: 16px;
  }

  span + p {
    margin-left: 8px;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  top: 20px;
  right: 30px;

  background: transparent;
  border: none;

  font-size: 16px;
  color: #999591;

  transition: color 0.2s;

  span {
    margin-left: 5px;
  }

  &:hover {
    color: #36a9e0;
  }
`;
