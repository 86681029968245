import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { parseISO } from 'date-fns';
import { Container, Error, ReactDatePickerElement } from './styles';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
}

const DatePicker: React.FC<Props> = ({
  name,
  containerStyle = {},
  icon: Icon,
  ...rest
}) => {
  const datepickerRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState<Date | null>(
    defaultValue ? parseISO(defaultValue) : null,
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName, // obrigatorio
      ref: datepickerRef.current, // obrigatorio
      getValue: ref => ref.props.selected,
      setValue: (ref, value) => {
        if (value) {
          ref.setSelected(parseISO(value as string));
        }
      },
      clearValue: ref => {
        ref.clear();
      },
    }); // eslint-disable-next-line
  }, [fieldName, registerField]);

  return (
    <Container style={containerStyle} isErrored={!!error} isFocused={isFocused}>
      {Icon && <Icon size={20} />}
      <ReactDatePickerElement
        dateFormat="dd/MM/yyyy"
        selected={date}
        ref={datepickerRef}
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={value => {
          if (value instanceof Date) setDate(value);
        }}
        todayButton="Today"
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default DatePicker;
