import styled from 'styled-components';
import ReactModal from 'react-modal';

interface ModalProps {
  tall: boolean;
}

export const StyledModal = styled(ReactModal)<ModalProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  /* align-items: center; */
  justify-content: center;
  background-color: rgba(0, 30, 70, 0.8);

  z-index: 1031;
`;
StyledModal.displayName = 'StyledModal';

export const Container = styled.div`
  margin-top: 10px;
  background-color: #fff;
  width: 100%;
  max-width: 506px;
  padding: 55px 75px 30px;
  position: relative;
  border-radius: 20px;
`;
Container.displayName = 'Container';
